<template>
  <el-upload
    v-if="multiple"
    :file-list="files"
    :action="action"
    multiple
    :name="name"
    :accept="accept"
    :data="data"
    list-type="picture-card"
    :auto-upload="true"
    :on-success="success"
    :headers="headers"
  >
    <i slot="default" class="el-icon-plus"></i>
    <div slot="file" slot-scope="{ file }">
      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
      <span class="el-upload-list__item-actions">
        <span
          class="el-upload-list__item-preview"
          @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleDownload(file)"
        >
          <i class="el-icon-download"></i>
        </span>
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
    </div>
  </el-upload>
  <el-upload
    v-else
    class="avatar-uploader"
    :action="action"
    :accept="accept"
    :show-file-list="false"
    :limit="1"
    :file-list="files"
    :multiple="false"
    :on-success="success"
    :name="name"
    :data="data"
    :on-progress="uploadFileProcess"
    :before-upload="beforeUpload"
    :headers="headers"
  >
    <div
      v-if="uploading"
      style="width: 100%; height: 100%; align-items: center; display: flex"
    >
      <el-progress
        type="circle"
        :percentage="progressPercent"
        style="width: 100%"
      ></el-progress>
    </div>

    <div v-else>
      <div
        @click.prevent.stop="clear"
        v-if="accept == 'image/*' && urls.length == 1"
        class="el-upload-list--picture-card"
        style="line-height: 0"
      >
        <img
          class="el-upload-list__item-thumbnail"
          :src="urls[0]"
          object-fit="contain"
          style="width: 150px; height: 150px; margin: 0 auto"
        />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-delete" @click="handleRemove1(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
      <div @click.prevent.stop="clear" class="el-upload-list--picture-card" v-else-if="accept == 'video/*' && urls.length == 1">
        <video style="width: 150px; height: 150px; margin: 0 auto" :src="urls[0]" class="avatar" />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-delete" @click="handleRemove1(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </div>
  </el-upload>
</template>

<script>
export default {
  name: "ImageUpload",
  data() {
    return {
      action: this.$host + "/admin/file/aliyunossupload",
      urls: [],
      files: [],
      name: "file",
      uploading: false,
      data: { token: "" },
      host: "",
      disabled: false,
      progressPercent: 0,
      headers: { Authorization: `Bearer ${localStorage.getItem("admin_token")}` },
    };
  },

  props: {
    value: {
      type: [String, Array],
      default: () => {
        return "";
      },
    },

    accept: {
      type: [String, Array],
      default: () => {
        return "image/*";
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    if (this.value) {
      if (this.multiple) {
        let _this = this;
        let index = 0;
        this.value.forEach((url) => {
          _this.urls.push(url);
          _this.files.push({ url: url, uid: index++ });
        });
      } else {
        let data = [];
        data.push(this.value);
        this.files.push({ url: this.value, uid: 1 });
        this.urls = data;
      }
    } else {
      this.urls.length = 0;
      this.files.length = 0;
    }
  },
  methods: {
    uploadFileProcess(progressEvent) {
      this.progressPercent = parseInt((progressEvent.loaded / progressEvent.total) * 100);
    },
    beforeUpload() {
      this.uploading = true;
    },
    clear(e) {
      e.stopPropagation();
    },
    handleRemove1() {
      this.urls.splice(0, 1);
      this.files.splice(0, 1);
      this.$emit("input", "");
    },
    handleRemove(file) {
      let index = 0;
      this.urls.some((item) => {
        if (file.url == item) {
          this.urls.splice(index, 1);
          this.files.splice(index, 1);
          if (this.multiple) {
            if (this.urls.length == 0) {
              this.$emit("input", "");
            } else {
              this.$emit("input", this.urls);
            }
          } else {
            this.$emit("input", "");
          }
        }
        index++;
      });
    },
    handleDownload() {},
    handlePictureCardPreview() {},
    handleClose() {},
    comfirm() {},
    success(res) {
      this.uploading = false;
      if (res.url) {
        if (!this.multiple) {
          this.urls.length = 0;
        }
        this.urls.push(res.url);
        this.files.push({ url: res.url, uid: this.urls.length });
        if (this.multiple) {
          this.$emit("input", this.urls);
        } else {
          this.$emit("input", this.urls[0]);
        }
      } else {
        this.$message(res.msg);
      }
    },
  },
};
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
