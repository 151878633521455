<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>订单列表</span>
      </div>

      <el-card>
        <div slot="header" class="clearfix">
          <el-form inline>
            <el-form-item label="商品类型">
              <el-select
                filterable
                remote
                style="width: 100%"
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="query_collections"
                :loading="loading"
                v-model="filterData.collection_id"
              >
                <el-option
                  v-for="item in collections"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="下单时间" style="margin-left: 10px">
              <el-date-picker
                v-model="filterData.createTime"
                type="datetimerange"
                value-format="yyyy-MM-DD HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item label="商品名称" style="margin-left: 10px">
              <el-input
                v-model="filterData.search"
                placeholder="输入关键字搜索"
                clearable
              ></el-input>
            </el-form-item>

            <el-form-item style="margin-left: 10px">
              <el-button @click="query(1)" type="primary">查询</el-button>
            </el-form-item>
          </el-form>
        </div>

        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="chStatus"
        >
          <el-menu-item index="">全部</el-menu-item>
          <el-menu-item index="0">未付款</el-menu-item>
          <el-menu-item index="1">待发货</el-menu-item>
          <el-menu-item index="2">待收货</el-menu-item>
          <el-menu-item index="3">已收货</el-menu-item>
          <el-menu-item index="4">已完成</el-menu-item>
          <el-menu-item index="5">待处理</el-menu-item>
          <el-menu-item index="6">已取消</el-menu-item>
          <el-menu-item index="7">回收站</el-menu-item>
        </el-menu>

        <el-card style="margin-top: 10px">
          <div slot="header">
            <el-row style="text-align: center">
              <el-col :span="16">订单信息</el-col>
              <el-col :span="4">实付金额</el-col>
              <el-col :span="4">操作</el-col>
            </el-row>
          </div>

          <el-card
            v-for="(item, index) in tableData"
            :key="index"
            style="margin-top: 10px"
          >
            <el-row>
              <el-col
                style="
                  display: flex;
                  justify-content: center;
                  height: 60px;
                  background: #f5f7fa;
                "
              >
                <div style="flex: 1; line-height: 60px">
                  <span style="margin: 0 20px">{{ item.createTime }}</span>
                  <span style="margin: 0 20px">订单号：{{ item.orderNo }}</span>
                  <span style="margin: 0 20px"
                    ><img
                      style="height: 22px; vertical-align: middle; margin-right: 5px"
                      src="../../assets/wx.png"
                    />{{ item.address.first_name }}{{ item.address.last_name }}</span
                  >
                  <el-tag
                    type="primary"
                    v-if="item.statusCode == 2"
                    style="margin: 0 10px"
                    >快递发送</el-tag
                  >
                  <el-tag type="warning">{{ OrderStateMap[item.status] }}</el-tag>
                  <el-tag type="danger" v-if="item.statusCode == 7" style="margin: 0 10px"
                    >已取消</el-tag
                  >
                </div>

                <div>
                  <el-button
                    @click="express(item.id)"
                    v-if="item.statusCode == 1"
                    type="success"
                    size="small"
                    style="margin-right: 10px"
                    >发货</el-button
                  >
                  <i
                    class="el-icon-delete"
                    style="
                      font-size: 30px;
                      line-height: 60px;
                      margin-right: 20px;
                      vertical-align: middle;
                    "
                  ></i>
                </div>
              </el-col>
            </el-row>
            <el-row
              style="margin: 10px 0"
              v-for="(item1, index) in item.products"
              :key="index"
            >
              <el-col :span="16">
                <div style="display: flex; just-content: center">
                  <div style="display: flex; flex: 12; just-content: center">
                    <el-image
                      style="width: 120px; height: 120px"
                      :src="(item1.images || [])[0]"
                      fit="cover"
                    ></el-image>
                    <div
                      style="
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                      "
                    >
                      <p>
                        {{ item1.title }}
                      </p>
                      <p style="margin-top: 10px">
                        规格：<el-tag size="small" type="primary">{{
                          item1.size
                        }}</el-tag>
                      </p>
                      <p style="margin-top: 10px">
                        材料：<el-tag size="small" type="success">{{
                          item1.material
                        }}</el-tag>
                      </p>

                      <p style="margin-top: 10px">
                        重量：<el-tag size="small" type="warning">{{
                          item1.weight
                        }}</el-tag>
                      </p>
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex: 6;
                      justify-content: center;
                      flex-direction: column;
                    "
                  >
                    价格：￥{{ item1.price }}
                  </div>
                  <div
                    style="
                      display: flex;
                      flex: 6;
                      justify-content: center;
                      flex-direction: column;
                    "
                  >
                    数量：x {{ item1.amount }}
                  </div>
                </div>
              </el-col>
              <el-col :span="4">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    height: 120px;
                  "
                >
                  <span>￥{{ item1.amount * item1.price }}</span>
                  <span>(含运费￥0.00)</span>
                  <!-- <el-tag type="warning">货到付款</el-tag> -->
                </div>
              </el-col>
              <el-col
                :span="4"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 120px;
                "
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="打印小票"
                  placement="top-start"
                >
                  <img style="height: 40px" src="../../assets/print.png" />
                </el-tooltip>

                <el-tooltip
                  style="margin: 0 20px"
                  class="item"
                  effect="dark"
                  content="添加备注"
                  placement="top-start"
                >
                  <img style="height: 40px" src="../../assets/add_remark.png" />
                </el-tooltip>

                <el-tooltip
                  class="item"
                  effect="dark"
                  content="查看详情"
                  placement="top-start"
                >
                  <img style="height: 40px" src="../../assets/detail.png" />
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <span style="margin: 0 20px">收货人: {{ item.address.name || item.address.first_name }}</span>
                <span style="margin: 0 20px">电话：{{ item.address.phone }}</span>
                <span style="margin: 0 20px"
                  >地址：{{ item.address.province }} {{ item.address.city }}
                  {{ item.address.county }} {{ item.address.detail }}</span
                >
              </el-col>
            </el-row>
          </el-card>
        </el-card>

        <el-row>
          <el-col :span="24" style="text-align: center; margin-top: 10px">
            <el-pagination
              background
              layout="total,prev, pager, next"
              @current-change="pageChange"
              :current-page="filterData.page"
              :total="total"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-card>
    </el-card>

    <el-dialog :title="title + '分类'" :visible.sync="dialogFormVisible">
      <el-card>
        <!-- <div slot="header">
          <span>添加分类</span>
        </div> -->
        <el-form :model="form" ref="form" label-width="120px">
          <el-form-item
            label="类⽬名称"
            prop="name"
            :rules="[{ required: true, message: '类⽬名称不能为空' }]"
          >
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="类⽬⽂案"
            prop="description"
            :rules="[{ required: true, message: '类⽬⽂案不能为空' }]"
          >
            <el-input
              type="textarea"
              :rows="3"
              v-model="form.description"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="排版⽅式"
            prop="layout"
            :rules="[{ required: true, message: '类⽬⻚排版⽅式不能为空' }]"
          >
            <el-input v-model="form.layout" autocomplete="off"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12"
              ><el-form-item
                label="图⽚1"
                prop="visual1"
                :rules="[{ required: true, message: '产品数量不能为空' }]"
              >
                <ImageUpload v-model="form.visual1"></ImageUpload> </el-form-item
            ></el-col>
            <el-col :span="12"
              ><el-form-item
                label="图⽚2"
                prop="visual2"
                :rules="[{ required: true, message: '产品数量不能为空' }]"
              >
                <ImageUpload v-model="form.visual2"></ImageUpload> </el-form-item
            ></el-col>
          </el-row>
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog center width="600px" title="标记发货" :visible.sync="dialogFormVisible1">
      <el-card>
        <!-- <div slot="header">
          <span>添加分类</span> 
        </div> -->
        <el-form :model="form1" ref="form1" label-width="120px">
          <el-form-item
            label="快递名称"
            prop="express"
            :rules="[{ required: true, message: '快递名称不能为空' }]"
          >
            <el-select
              filterable
              style="width: 100%"
              reserve-keyword
              value-key="code"
              placeholder="请输入关键词"
              v-model="form1.express"
              remote
              :remote-method="query_express"
              :loading="loading"
            >
              <el-option
                v-for="item in expresses"
                :key="item.code"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="快递单号"
            prop="express_no"
            :rules="[{ required: true, message: '快递单号不能为空' }]"
          >
            <el-input v-model="form1.express_no" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="发货时间" prop="layout">
            <el-date-picker
              type="datetime"
              style="width: 100%"
              v-model="form1.express_time"
              value-format="yyyy-MM-DD HH:mm:ss"
              placeholder="默认为当前提交时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="saveExpress()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ImageUpload from "../common/ImageUpload.vue";
import { OrderStateMap } from "./enum";
export default {
  components: { ImageUpload },
  data() {
    return {
      activeIndex: "",
      collections: [],
      items: [],
      title: "添加",
      total: 0,
      rule: [],
      filterData: {},
      tableData: [],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      form: {},
      form1: {},
      loading: false,
      page: 1,
      expresses: [],
      OrderStateMap,
    };
  },

  created() {
    this.query();
    this.query_collections();
    this.query_express();
  },

  methods: {
    pageChange(page) {
      this.page = page;
      this.query();
    },

    chStatus(statusCode) {
      this.filterData.statusCode = statusCode;
      this.query(1);
    },
    query(page) {
      if (page) this.page = page;
      this.$http
        .post("/admin/order/list?page=" + this.page, this.filterData)
        .then((res) => {
          if (res.status == 200) {
            this.tableData = res.data.data;
            this.total = res.data.total;
          }
        })
        .catch(() => {});
    },
    query_collections(name) {
      this.loading = true;
      this.$http
        .post("/admin/collection/list", { name: name })
        .then((res) => {
          this.collections = res.data.data;
          this.loading = false;
        })
        .catch(() => {});
    },

    query_express(name) {
      this.loading = true;
      this.$http
        .post("/admin/express/list", { name: name })
        .then((res) => {
          this.expresses = res.data.data;
          this.loading = false;
        })
        .catch(() => {});
    },

    express(id) {
      this.form1 = { order_id: id };
      this.dialogFormVisible1 = true;
    },

    saveExpress() {
      this.$refs.form1.validate((valid) => {
        if (valid) {
          this.$http.post("/admin/order/express", this.form1).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.dialogFormVisible1= false;
              this.query();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    remove(id) {
      this.$confirm("此操作将永久删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.post("/admin/user/remove", { id: id }).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.query();
            }
          });
        })
        .catch(() => {});
    },

    edit(row) {
      this.title = "修改";
      this.form = row;
      this.dialogFormVisible = true;
    },

    add() {
      this.title = "添加";
      this.dialogFormVisible = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.post("/admin/user/save", this.form).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.dialogFormVisible = false;
              this.form = {};
              this.query();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
